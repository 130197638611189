import React from "react";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {
    // Title,
    Box
} from "../Core";
import Logo from "../Logo";
import {Link} from "gatsby";

const AForgot = styled(Link)`
  color: ${({ theme }) => theme.colors.secondary} !important;
  text-decoration: none !important;
`;

const CopyRightArea = styled.div`
  border-top: ${({dark, theme}) =>
          dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;

  p {
    color: ${({dark, theme}) =>
            dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;

    a {
      color: ${({dark, theme}) =>
              dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;

      &:visited {
        text-decoration: none;
      }

      &:hover {
        text-decoration: none;
        color: ${({theme, color}) => theme.colors.primary} !important;
      }
    }
  }
`;

const Footer = ({isDark = true}) => {
    return (
        <>
            {/* <!-- Footer section --> */}
            <Box bg={isDark ? "dark" : "light"}>
                <Container>
                    <Box
                        css={`
                          padding: 80px 0 60px;
                        `}
                    >
                        <Row className="justify-content-center">
                            <Col lg="2" md="4">
                                <Logo white={isDark}/>
                            </Col>
                            <Col lg="10" md="8" className="mt-5 mt-lg-0">
                            </Col>
                        </Row>
                    </Box>
                    <CopyRightArea dark={isDark ? 1 : 0}>
                        <Row className="align-items-center">
                            <Col sm="6" className="text-sm-left text-center mb-2 mb-sm-0">
                                <p>&copy; {new Date().getFullYear()}, All Rights Reserved
                                    <AForgot to="/">Terms & Conditions</AForgot>
                                </p>
                            </Col>
                            <Col sm="6" className="text-sm-right text-center">
                                <ul className="social-icons">
                                    <li>
                                        <a href="https://twitter.com/fishermandev" target="_blank" rel="noreferrer">
                                            <i className="icon icon-logo-twitter"></i>
                                        </a>
                                    </li>
                                    {/*<li>*/}
                                    {/*  <a href="/#" target="_blank">*/}
                                    {/*    <i className="icon icon-logo-linkedin"></i>*/}
                                    {/*  </a>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*  <a href="/#" target="_blank">*/}
                                    {/*    <i className="icon icon-logo-facebook"></i>*/}
                                    {/*  </a>*/}
                                    {/*</li>*/}
                                </ul>
                            </Col>
                        </Row>
                    </CopyRightArea>
                </Container>
            </Box>
        </>
    );
};

export default Footer;
